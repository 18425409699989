#my-box {
    --s:380px;  /*Size of the circle */
    --p:20px;   /* padding */

    text-align: justify;
    height: var(--s);
    width: var(--s);
}


#my-box i,
#my-box::before {
  content: '';
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: radial-gradient(farthest-side at right, transparent calc(100% - var(--p)), #fff 0);
}


#my-box i {
    float: right;
    shape-outside: radial-gradient(farthest-side at left,  transparent calc(100% - var(--p)), #fff 0);
  }